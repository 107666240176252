import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

const httpOptions = {headers: new HttpHeaders({'Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods':'POST,GET,OPTIONS,PUT','Accept':'application/json','Content-Type':'application/json'})};
//const httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'})};
const apiUrl = 'https://www.red-app.at/api';
const configUrl = 'assets/';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  postUserData(data): Observable<any> {
    const url = `${apiUrl}/login.php`;
    return this.http.post(url, data, httpOptions).pipe(map(this.extractData),catchError(this.handleError));
  }

  getjson(jsonurl: string) {
    return this.http.get(configUrl + jsonurl).pipe(map(this.extractData),catchError(this.handleError));
  }

  updateUserData(data): Observable<any> {
    const url = `${apiUrl}/update.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }
  updateUserDataisadmin(data): Observable<any> {
    const url = `${apiUrl}/updateuserisadmin.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }
  updateUserDataisinaktiv(data): Observable<any> {
    const url = `${apiUrl}/updateuserinaktiv.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }

  searchUser(data): Observable<any> {
    const url = `${apiUrl}/select.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }

  searchData(data): Observable<any> {
    const url = `${apiUrl}/search.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }
  
  searchDetail(data): Observable<any> {
    const url = `${apiUrl}/searchdetail.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }

  insertData(data): Observable<any> {
    const url = `${apiUrl}/inserterr.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }

  insertUser(data): Observable<any> {
    const url = `${apiUrl}/insert.php`;
    return this.http.post(url, data, httpOptions).pipe(catchError(this.handleError));
  }

}
