import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

//Zuständig für globale Variablen
export class GlobalService {
  //Sprache, wird aus Datenbank ausgelesen und nach Änderung wieder in diese gespeichert
  public languageglobal: string = 'eng';
  //Zum Etweigen speichern von Sprachänderung
  public useridglobal: number = 0;
  //Zur Übergabe von Informationen von einer WebSite zur nächsten
  public searchDetail: any = [];
  //Abfragevariable, ob angemeldet oder nicht
  public loggedIn: boolean = false;
  //Admin JA/NEIN
  public isAdmin: boolean = false;
  //Baugruppensuche
  public assambleysearch: string = "";
  //Baugruppe kommt von  
  public assambleycomefrom: string = "";
  //Produkt kommt von
  public productcomefrom: string = "";
  //Aktuelles Produkt
  public currentproduct: string = "";
  constructor() { }
}
